import React from 'react';
import Utils from '../../Utils/Utils';
import styles from './Welcome.module.scss';

const Welcome = () => {

    const title = Utils.makeTitle('grindletime');


    return (
        <div className={styles.welcome}>
            <div>
                <h1>{title.map((l, i) => <span key={i}>{l}</span>)}</h1>
            </div>

        </div>
    )

};

export default Welcome;

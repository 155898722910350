import React, {useEffect, useState} from 'react';
import Clock from "./layout/Clock/Clock";
import Number from './layout/Number/Number';
import Spacer from "./layout/Spacer/Spacer";
import Welcome from "./layout/Welcome/Welcome";

const App = () => {


    const [grindletime, setGrindletime] = useState({
        samlets: 0,
        grindons: 0,
        grindets: 0
    });

    useEffect(() => {

        // Set interval of every 864 Milliseconds
        const interval = setInterval(() => {


            const fullDay = 24 * 60 * 60 * 1000;

            // Use new Date() to get users timezone
            const now = new Date();
            // Gert day in Milliseconds
            const hours = now.getHours() * 60 * 60 * 1000;
            const mins = now.getMinutes() * 60 * 1000;
            const sec = now.getSeconds() * 1000;
            const mill = now.getMilliseconds();

            // Get fraction based on a days full Milliseconds
            const dayFraction = (hours + mins + sec + mill) / fullDay;

            //Get indivudal numbers
            const samlets = Math.floor(dayFraction * 10);
            const grindons = Math.floor(dayFraction * 1000) - (samlets * 100);
            const grindets = Math.floor(dayFraction * 100000) - (grindons * 100) - (samlets * 10000);

            setGrindletime({
                samlets: samlets,
                grindons: grindons,
                grindets: grindets
            });

        }, 864);

        // Clear on "unmount"
        return () => clearInterval(interval);

    }, [])

    return (

        <React.Fragment>

            <Welcome/>

            <Clock>
                <Number value={grindletime.samlets} />
                <Spacer/>
                <Number value={grindletime.grindons} />
                <Spacer/>
                <Number value={grindletime.grindets} />
            </Clock>

        </React.Fragment>


    );
}
export default App;

import React from 'react';
import styles from './Number.module.scss';
import Slider from "./Slider/Slider";

const Number = props => {


    const display = props.value < 10 ? '0' + props.value : props.value;

    return (
        <div className={styles.number}>
            <span><Slider number={String(display).slice(0,1)}/></span>
            <span><Slider number={String(display).slice(1,2)}/></span>
        </div>
    )


}

export default React.memo(Number);

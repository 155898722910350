import React from 'react';
import styles from './Clock.module.scss';

const Clock = props => (
    <div className={styles.clock}>
        <div className={styles.clockInner}>
            {props.children}
        </div>

    </div>
)
export default Clock;

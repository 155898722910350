import React, {useState, useEffect, useCallback} from "react";
import styles from './Slider.module.scss';

const Slider = props => {

    const [current, setCurrent] = useState(0);
    const [next, setNext] = useState(0);
    const [prev, setPrev] = useState(0);
    const [change, setChange] = useState(false);

    const animateNumber = useCallback(() => {
        setNext(props.number);
        setPrev(current);
        setChange(true);
    }, [current, props.number]);

    const pauseNumber = useCallback(() => {
        setChange(false);
        setCurrent(props.number);
    }, [props.number]);

    useEffect(()=>{

        //Use callback function to get around warnings
        animateNumber();

        const interval = setInterval(() => {
            //Use callback function to get around warnings
            pauseNumber();
        }, 300);

        // Clear on "unmount"
        return () => clearInterval(interval);

    }, [props.number, pauseNumber, animateNumber]);



    //Set classes
    const nextClasses = change ?  [styles.next, styles.nextAnimate] : [styles.next];
    const currentClasses = change ? [styles.current, styles.currentAnimate] : [styles.current];
    const prevClasses = change ? [styles.prev, styles.prevAnimate] : [styles.prev];


    return (
        <div className={styles.slider}>
            <div className={nextClasses.join(" ")}>{next}</div>
            <div className={currentClasses.join(" ")}>{current}</div>
            <div className={prevClasses.join(" ")}>{prev}</div>
        </div>
    )
}

export default React.memo(Slider);

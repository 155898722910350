class Utils
{
    static makeTitle(title) {

        let newTitle = []

        for (let i=0; i < title.length; i++){
            newTitle.push(title[i])
        }

        return newTitle;

    }
}
export default Utils;
